.App {
  text-align: center;  
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.accordion {
	--bs-accordion-btn-color: ghostwhite;
	--bs-accordion-btn-bg: rgb(45, 43, 44);
	--bs-accordion-active-color: rgb(234, 141, 83);
	--bs-accordion-active-bg: rgb(45, 43, 44);
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
		
	}

	70% {
		transform: scale(1.1);
    
		
	}

	100% {
		transform: scale(0.95);
		
	}
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.zoom {
  
  transition: transform .2s; /* Animation */
  
  margin: 0 auto;
}

.grow { 
  transition: all .2s ease-in-out; 
  }
  
  .grow:hover { 
  transform: scale(1.1); 
  }

.zoom:hover {
  transform: scale(1.05); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.fade-in {
	opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 2s;
}
.carousel-control-next-icon {
  color:black;
}

 h2:hover{
  background-color: white;
  color:#282c34;
}
.fade-inNav {
	opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: .5s;
}

@keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
